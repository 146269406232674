.discover .swiper-pagination {

}
.discover .swiper-pagination-bullet {
  background: var(--white) !important;
  --swiper-pagination-bullet-horizontal-gap: 3px;
  transform: scale(0.7) !important;
  opacity: 1 !important;
  margin: 0 var(--swiper-pagination-bullet-horizontal-gap);
}
.discover .swiper-pagination-bullet-active {
  transform: scale(1.2)!important;
}

.swiper-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic, .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic{
  transform: none;
  left: calc(50% - 98px);
  padding: 2px 0;
  height: 11px;
  margin: 0;
  bottom: 29px;
  z-index: 12;
}